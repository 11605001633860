<template>
  <div class="page-index">
    <Navbar title="领域" source="role" :type="1"  :loading="loading"></Navbar>
    <img src="@/assets/images/bg_gradient.png" alt="" class="bg">
    <div class="m-lr-40">
      <RoleCard
        :list="defaultList"
        :needAva="false"
        class="pre"
        source="role"
        :needStatus="false"
       

      ></RoleCard>
      <p class="basic-font">我的领域</p>
      <van-field
        v-model="keywords"
        left-icon="search"
        placeholder="搜索领域"
        @focus="visible=false"
        @blur="visible=true"
        :border="false"
      />
      <van-list v-model="loading" :finished="finished" finished-text="" @load="onLoad" offset="100">
        <RoleCard :list="list" source="role"  :loading="loading" ></RoleCard>
      </van-list>
    </div>
    <transition :name="`${visible ? 'van-slide-up' : ''}`">
      <Tabbar activeIndex="0" v-show="visible"></Tabbar>
    </transition>
  </div>
</template>
<script>
export default {
  data() {
    return {
      defaultList: [
        {
          title: "默认领域",
          type: 0,
        },
      ],
      visible: true,
      list: [
        // {
        //   name: "心理医生",
        //   desc: "协助处理情绪、压力、焦虑、社交恐惧…",
        //   color: "#24BE48",
        // },
        // {
        //   name: "历史专家",
        //   desc: "了解历史的演变及各个时代变化。",
        //   color: "#BE9C24",
        // },
        // {
        //   name: "虚拟男友",
        //   desc: "协助处理情绪、压力、焦虑、社交社交恐社交恐社交恐社交恐社交恐",
        //   color: "#2462BE",
        // },
        // {
        //   name: "AI绘画",
        //   desc: "协助处理情绪、压力、焦虑、社交恐惧…",
        //   color: "#7124BE",
        // },
        // {
        //   name: "健身教练",
        //   desc: "协助处理情绪、压力、焦虑、社交恐惧…",
        //   color: "#C23291",
        // },
      ],
      loading: false,
      finished: false,
      keywords: "",
      queryKeys: {
        userId: localStorage.getItem("id"),
        title: "",
        type: 1,
        pageNum: 1,
        pageSize: 7,
      },
    };
  },
  created() {
    // this.getRoleList();
  },
  mounted() {
  },
  watch: {
    keywords(cur) {
      this.list = [];
      this.queryKeys.pageNum = 1;
      this.queryKeys.cancel = true;
      this.queryKeys.title = cur;
      this.getRoleList();
    },
  },
  methods: {
    async getRoleList() {
      this.loading=true
      const res = await this.$api.role.getRoleList(this.queryKeys);
      this.list = [...this.list, ...res.data.rows];
      this.loading = false;
      if(this.queryKeys.pageSize>res.data.rows.length){
        this.finished=true
        return
      }
      
      this.queryKeys.pageNum++
      
    },
    onLoad() {
      this.getRoleList()
    },
  },
};
</script>
<style lang="less" scoped>


.m-lr-40 {
  margin-left: 0.4rem;
  margin-right: 0.4rem;
}
.basic-font {
  font-size: 0.32rem;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
}
.page-index {
  padding-bottom: calc(1rem + constant(safe-area-inset-bottom));
  padding-bottom: calc(1rem + env(safe-area-inset-bottom));
  min-height: 100vh;
  background-color: #f5f7fb;
  position: relative;

  .pre {
    position: relative;
    z-index: 1;
    margin: 0.6rem 0;
    font-size: 0.32rem;

    span {
      font-size: 0.32rem;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
    }
  }
  p {
    position: relative;
    padding-left: 0.08rem;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 0.04rem;
      height: 0.24rem;
      transform: translateY(50%);
      background: #294bf9;
      border-radius: 0.2rem;
    }
  }
  ::v-deep.van-field {
    margin: 0.4rem 0;
    color: #3c3c43;
    font-size: 0.34rem;
    background: #ffffff;
    border-radius: 0.2rem;
    .van-field__left-icon {
      display: flex;
      align-items: center;
      color: #8e8e93;
    }
    .van-icon-search {
      font-size: 0.32rem;
    }
  }
}
</style>